<template>
  <div class="goodNews">
    <Head classA="3" headBg="1"></Head>
    <div class="main">
      <h2>用户心声第九期│四川遂宁大东街社区卫生服务站</h2>
      <p class="time">2019-12-5</p>
      <p>
        在国家政策指引下，基层医疗也搭上了“互联网+”的高速列车。但在技术力量薄弱的基层，基层医生如何才能享受到政策带来的红利，如何搭上“互联网+医疗健康”快车？来自四川遂宁的黄大夫心中有了答案。
      </p>
      <p>
        随着时代的不断进步，基层医务工作者在人们的生活中扮演着越来越重要的角色，为给群众提供更优质的服务与良好的就医体验，他们工作愈加精细化，工作强度与压力也逐渐增大。
      </p>
      <p>
        由于基层公共卫生服务并未普及智能化设备，只有工作结束再逐个整理居民健康档案，手动录入相关数据，工作量大，经常加班加点，耗费大量人力和时间。
      </p>
      <p>
        作为一名基层医者，四川遂宁大东街社区卫生服务站的黄安全医生，也经历了从老客新治事倍功半，到轻松获取患者档案；从手写处方溯源困难，到电子处方一键查找；从人工喊号维持秩序，到云屏叫号有序就诊；从算盘管账一头雾水，到统计报表一目了然。他有很多想跟我们说的，让我们一起走进他的故事吧。
      </p>
      <h4>1、患者档案</h4>
      <p>
        黄：多数病人都是老患者，经常在这里看病，你凭记忆的话，肯定是记不住那么多病人，那么每个病人你要重新去想，每个病人都要当新病人一样的。过去上班上的时间久的话，一般是九点多十点下班。
      </p>
      <p>
        现在来看的病人，你告诉他有患者档案，你就轻松多了，你了解的各方面的内容就很更快些，很准确。现在我们一般都是七点钟六点半就准备下班了。
      </p>
      <h4>2、处方查找</h4>
      <p>
        黄：过去有些患者三个月、半年过后，找到我说“我上次吃的那个药好的很，吃了我就管到现在了，这次你帮我把头回的单子找一下行不行”，我们遇到这种问题就特别难。
        还有个别的病人来的时候，我们就问了她是否怀孕，或者用药有什么过敏，当时她可能觉得没有，等她去把药吃了一个月或好长时间后，她查出来自己怀孕了，要来找一下我的单子，是不是那个药不可以吃，有没有不良反应，孩子可不可以要？这种就非常麻烦，要花起码三个小时，有可能四个小时，或者更长的时间，才能把那个处方调出来。
      </p>
      <p>现在很简单，只要名字一输进去，他的处方就出来了，那就很方便。</p>
      <h4>3、患者管理</h4>
      <p>
        黄：28年来都是手写处方，过去病人来看病，往次在我这里看了病，用了些什么药，有什么情况，我们都不了解。你要花很多时间想，去问他过去的病史、用药情况等。
      </p>
      <p>
        黄：自从用了你们这个（致医云诊室）过后，我们对每一个人的管理是特别好。比如说这个病人上一次用的什么药，今天病人一来名字一输，他的档案一下就出来了，所有在我这个地方看过的病，在我这个诊所里头用了什么药，有什么效果没有，跟病人一说，病人都很高兴，病人觉得这对他也是比较好，比较负责任的。还有我们对病人的诊断治疗用药就更准确一些，效果也就更明显一些。
      </p>
      <h4>4、叫号排队</h4>
      <p>黄：这些病人走过来问，黄医生我还得好久嘛？</p>
      <p>
        黄：现在通过了这个云屏叫号，病人喊号或者就医次序得到了明显的改善，我能够集中精力下来看病，也避免了这些病人在这里吵、在这里闹。
      </p>
      <h4>5、财务管理</h4>
      <p>
        黄：过去的时候算账是个最大的问题，每个处方开了以后，我都要亲自来核算账。收益以前是一个未知数，自己没数，到底哪个医生开了多少药，有多少门诊量，都没有一个具体的数字。
      </p>
      <p>
        黄：现在单子一开完就都有了，外面护士拿起开药的单子，也不用找我来算账了。所以现在基本上都有数，哪个医生开多少药，看了多少门诊，自己心里都有底了。
      </p>
      <h4>6、对比优势</h4>
      <p>
        黄：我先接触了一个软件，也是一个比较知名的电子处方（软件），当时没做起来的。但是做你们这个，在你们工作人员精心的努力下，我们也把它做好了，现在基本是做的可以了，他们的服务还是跟不上，因为医生我们一天都非常忙，那么很多东西出现问题，就没有人来管，用你们这个如果我们出现了问题的话，马上你们就有售后，这个售后你们是做的非常优秀的。
      </p>
    </div>
    <Foot></Foot>
  </div>
</template>
<style scoped>
@import '../../assets/style/goodNews.css';
</style>
<script>
import Foot from '@/components/common/Foot'
import Head from '@/components/common/Head'

export default {
  name: 'news',
  components: {
    Head,
    Foot
  },
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>
